<template>
  <div class="min-h-full min-h-screen flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>

          <img
            src="@/assets/mfw-logo-dark.svg"
            class="mb-2 w-16 h-16"
          >
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Sign up on MYFARMWEB
          </h2>
          <p class="mt-2 text-sm text-gray-600">
            Or
            {{ ' ' }}
            <router-link
              to="/signup"
              class="text-primary-500"
            >
              sign in
            </router-link>
          </p>
        </div>
        <mfw-form
          :show-shadow="false"
        >
          <mfw-form-fields>
            <!-- First name -->
            <mfw-form-group label="First name">
              <mfw-form-input
                v-model="state.firstName"
                :validator="v$.firstName"
              />
            </mfw-form-group>

            <!-- Surname -->
            <mfw-form-group label="Last name">
              <mfw-form-input
                v-model="state.surname"
                :validator="v$.surname"
              />
            </mfw-form-group>

            <!-- E-mail address -->
            <mfw-form-group label="E-mail">
              <mfw-form-input
                v-model="state.email"
                :validator="v$.email"
              />
            </mfw-form-group>

            <!-- Mobile number -->
            <mfw-form-group label="Mobile number">
              <mfw-form-phone-number
                id="mobile-number"
                v-model="state.mobileNumber"
                :validator="v$.mobileNumber"
              />
            </mfw-form-group>

          </mfw-form-fields>

          <mfw-form-button-group>
            <mfw-form-button
              class="min-w-full sm:min-w-50 ml-0 bg-primary"
              type="submit"
              @click="onSubmit"
            >
              Next
            </mfw-form-button>
          </mfw-form-button-group>

        </mfw-form>

      </div>
    </div>

    <div class="hidden md:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="@/assets/mfw-splash.jpg"
        alt=""
      >
    </div>
  </div>
</template>


<script>
  import useValidate from '@vuelidate/core'
  import { email, helpers, minLength, required } from '@vuelidate/validators'
  import { computed, reactive } from 'vue'

  import MfwForm from '@/components/forms/MfwForm'
  import MfwFormFields from '@/components/forms/MfwFormFields'
  import MfwFormGroup from '@/components/forms/MfwFormGroup'
  import MfwFormInput from '@/components/forms/MfwFormInput'
  import MfwFormButton from '@/components/forms/MfwFormButton'
  import MfwFormButtonGroup from '@/components/forms/MfwFormButtonGroup'

  import MfwFormPhoneNumber from '@/components/forms/MfwFormPhoneNumber'

  export default {
    components: {
      MfwFormInput,
      MfwFormPhoneNumber,
      MfwForm,
      MfwFormFields,
      MfwFormGroup,
      MfwFormButton,
      MfwFormButtonGroup,
    },
    setup() {
      const state = reactive(
        {
          firstName: '',
          surname: '',
          email: '',
          mobileNumber: '',
          gender: '',
          country: '',
        }
      )

      const rules = computed(
        () => ({
          firstName: { required: helpers.withMessage('First name is required', required), },
          surname: {
            required: helpers.withMessage('Surname is required', required),
            minLength: helpers.withMessage('Surname must be at least 3 characters', minLength(3)),
          },
          email: {
            required: helpers.withMessage('E-mail address is required', required),
            email: helpers.withMessage('E-mail address is not valid', email),
          },
          mobileNumber: { required: helpers.withMessage('Mobile number is required', required), }
        })
      )

      const v$ = useValidate(rules, state)
      return {
        state,
        v$,
      }
    },
    methods: {
      onSubmit() {
        this.v$.$validate()
      },
      onReset() {
      },
    }
  }
</script>
