<template>
  <SidebarMenu title="Profile" />

  <PageContent>
    <div class="max-w-7xl mx-auto px-8 sm:px-6 md:px-32">
      <form class="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
        <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div class="sm:col-span-6">
            <label
              for="photo"
              class="block text-sm font-medium text-blue-gray-900"
            >
              Photo
            </label>
            <div class="mt-1 flex items-center">
              <img
                class="inline-block h-24 w-24 rounded-full"
                src="@/assets/sample-user.jpg"
                alt=""
              >
              <div class="ml-4 flex">
                <div
                  class="
                    relative
                    bg-white
                    py-2
                    px-3
                    border border-blue-gray-300
                    rounded-md
                    shadow-sm
                    flex
                    items-center
                    cursor-pointer
                    hover:bg-blue-gray-50
                    focus-within:outline-none
                    focus-within:ring-2
                    focus-within:ring-offset-2
                    focus-within:ring-offset-blue-gray-50
                    focus-within:ring-blue-500
                  "
                >
                  <label
                    for="user-photo"
                    class="
                      relative
                      text-sm
                      font-medium
                      text-blue-gray-900
                      pointer-events-none
                    "
                  >
                    <span>Change</span>
                    <span class="sr-only"> user photo</span>
                  </label>
                  <input
                    id="user-photo"
                    name="user-photo"
                    type="file"
                    class="
                      absolute
                      inset-0
                      w-full
                      h-full
                      opacity-0
                      cursor-pointer
                      border-gray-300
                      rounded-md
                    "
                  >
                </div>
                <button
                  type="button"
                  class="
                    ml-3
                    bg-transparent
                    py-2
                    px-3
                    border border-transparent
                    rounded-md
                    text-sm
                    font-medium
                    text-blue-gray-900
                    hover:text-blue-gray-700
                    focus:outline-none
                    focus:border-blue-gray-300
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-offset-blue-gray-50
                    focus:ring-blue-500
                  "
                >
                  Remove
                </button>
              </div>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium text-blue-gray-900"
            >
              First name
            </label>
            <input
              id="first-name"
              type="text"
              name="first-name"
              autocomplete="given-name"
              class="
                mt-1
                block
                w-full
                border-blue-gray-300
                rounded-md
                shadow-sm
                text-blue-gray-900
                sm:text-sm
                focus:ring-blue-500 focus:border-blue-500
              "
            >
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-blue-gray-900"
            >
              Surname
            </label>
            <input
              id="last-name"
              type="text"
              name="last-name"
              autocomplete="family-name"
              class="
                mt-1
                block
                w-full
                border-blue-gray-300
                rounded-md
                shadow-sm
                text-blue-gray-900
                sm:text-sm
                focus:ring-blue-500 focus:border-blue-500
              "
            >
          </div>

          <div class="sm:col-span-3">
            <label
              for="email-address"
              class="block text-sm font-medium text-blue-gray-900"
            >
              Email address
            </label>
            <input
              id="email-address"
              type="text"
              name="email-address"
              autocomplete="email"
              class="
                mt-1
                block
                w-full
                border-blue-gray-300
                rounded-md
                shadow-sm
                text-blue-gray-900
                sm:text-sm
                focus:ring-blue-500 focus:border-blue-500
              "
            >
          </div>

          <div class="sm:col-span-3">
            <label
              for="phone-number"
              class="block text-sm font-medium text-blue-gray-900"
            >
              Phone number
            </label>
            <input
              id="phone-number"
              type="text"
              name="phone-number"
              autocomplete="tel"
              class="
                mt-1
                block
                w-full
                border-blue-gray-300
                rounded-md
                shadow-sm
                text-blue-gray-900
                sm:text-sm
                focus:ring-blue-500 focus:border-blue-500
              "
            >
          </div>

          <div class="sm:col-span-3">
            <label
              for="country"
              class="block text-sm font-medium text-blue-gray-900"
            >
              Country
            </label>
            <select
              id="country"
              name="country"
              autocomplete="country-name"
              class="
                mt-1
                block
                w-full
                border-blue-gray-300
                rounded-md
                shadow-sm
                text-blue-gray-900
                sm:text-sm
                focus:ring-blue-500 focus:border-blue-500
              "
            >
              <option>South Africa</option>
              <option>Namibia</option>
            </select>
          </div>

          <div class="sm:col-span-3">
            <label
              for="country"
              class="block text-sm font-medium text-blue-gray-900"
            >
              Language
            </label>
            <select
              id="language"
              name="language"
              autocomplete="language-name"
              class="
                mt-1
                block
                w-full
                border-blue-gray-300
                rounded-md
                shadow-sm
                text-blue-gray-900
                sm:text-sm
                focus:ring-blue-500 focus:border-blue-500
              "
            >
              <option>English</option>
              <option>Afrikaans</option>
            </select>
          </div>

          <p class="text-sm text-blue-gray-500 sm:col-span-6">
            This account was created on
            <time
              datetime="2021-10-01T20:35:40"
            >November 1, 2021, 8:35:40 PM
            </time>
            .
          </p>
        </div>

        <div class="pt-8 flex justify-end">
          <button
            type="submit"
            class="
              ml-3
              inline-flex
              justify-center
              py-2
              px-4
              border border-transparent
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-white
              bg-blue-600
              hover:bg-blue-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-blue-500
            "
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </PageContent>
</template>

<script>
  import PageContent from '@/components/layout/PageContent'
  import SidebarMenu from '@/components/layout/SidebarMenu'

  export default {
    components: {
      SidebarMenu,
      PageContent,
    },
    setup() {
      return {}
    },
  }
</script>
