<template>
  <div class="pt-8 flex justify-end">
    <slot />
  </div>
</template>


<script>
  export default { name: 'MfwFormButtonGroup' }
</script>
