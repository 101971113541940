<template>

  <div :class="responsive ? 'sm:col-span-3' : 'sm:col-span-6'">

    <label
      :for="id"
      class="mfw-form-label"
    >
      {{ label }}
    </label>

    <slot />

    <dd class="mfw-form-description">
      {{ description }}
    </dd>

  </div>

</template>


<script>

  export default {
    name: 'MfwFormGroup',
    props: ['id', 'name', 'label', 'description', 'responsive'],
  }

</script>


<style>

  .mfw-form-label {
    @apply
      block
      text-sm
      font-medium;
  }

  .mfw-form-field{
    @apply
      block
      border-gray-300
      focus:ring-1
      focus:ring-primary-500
      focus:border-primary-700
      mt-1
      rounded-md
      sm:text-sm
      w-full
    ;
  }


</style>