<template>
  <div class="md:pl-64 flex flex-1">
    <main class="flex-1 pb-8 pt-2 mt-4">
      <div class="max-w-7xl mx-auto px-8 sm:px-6 md:px-32">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
  export default {
    name: 'PageContent',
    props: {
      msg: {
        type: String,
        required: false,
        default: ''
      },
    },
  }
</script>
