
<template>

  <button
    type="button"
    :class="isSubmitButton"
    @click="onClick"
  >
    <slot />
  </button>

</template>


<script>

  export default {

    name: 'MfwFormButton',
    props: ['type'],
    emits: ['click'],

    computed: {
      isSubmitButton: function() {
        return this.type === 'submit'
          ? 'mfw-button mfw-button-submit'
          : 'mfw-button'
      }
    },

    methods: {
      onClick: function() {
        this.$emit('click')
      }
    }
  }

</script>


<style>

  .mfw-button {
    @apply
      bg-primary-700
      focus:outline-none
      focus:ring-2
      focus:ring-offset-2
      focus:ring-primary-500
      font-medium
      hover:bg-primary-600
      hover:text-primary-50
      py-2
      px-4
      rounded-md
      text-sm
      text-primary-100
    ;
  }

  .mfw-button-submit {
    @apply
      bg-primary-500
      hover:bg-primary-400
      hover:text-white
      text-primary-50
    ;
  }

  .mfw-button:not(:last-child){
    @apply mr-4
  }

</style>