<template>
  <p class="mfw-form-error h-4">
    <span v-if="error">{{ error }}</span>
  </p>
</template>


<script>
  export default {
    name: 'MfwFormError',
    props: ['error']
  }
</script>


<style>

.mfw-form-error {
  @apply
  mt-0.5
  text-xs
  font-medium
  text-red-600
  w-max
  ;
}

</style>
