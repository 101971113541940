<template>
  <SidebarMenu title="Instructions" />

  <PageContent>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      View and create instructions

      <div class="py-4">
        <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" />
      </div>
    </div>
  </PageContent>
</template>

<script>
  import SidebarMenu from '@/components/layout/SidebarMenu'
  import PageContent from '@/components/layout/PageContent'

  export default {
    components: {
      SidebarMenu,
      PageContent,
    },
    setup() {
      return {}
    },
  }
</script>
