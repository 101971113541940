<template>

  <div class="mfw-form-phone ">

    <div class="fields">
      <select
        ref="dialCode"
        v-model="dialCode"
        class="mfw-form-field phone-dialcode"
        @change="onInput"
      >

        <option
          v-for="country in countries"
          :key="country.id"
          :value="country.dialcode"
        >
          {{ toFlag(country.id) }}&nbsp;&nbsp;{{ country.title }}
        </option>

      </select>

      <input
        ref="number"
        v-model="number"
        type="text"
        autocomplete="off"
        class="mfw-form-field phone-number"
        @input="onInput"
      >

    </div>

    <MfwFormError :error="error" />

  </div>
</template>


<script>

  import MfwFormFieldMixin from './mixins/MfwFormField.mixin'
  import MfwFormError from './MfwFormError'

  import countries from '@/assets/countries.json'


  export default {

    name: 'MfwFormPhone',
    components: { MfwFormError },
    mixins: [ MfwFormFieldMixin ],
    props: ['modelValue'],
    emits: ['update:modelValue'],

    data() {
      return {
        dialCode: countries[0].dialcode,
        number: ''
      }
    },

    computed: {
      countries() {
        return countries
      }
    },


    methods: {

      onInput() {

        const
          dialCode = this.$refs.dialCode.value,
          number = this.$refs.number.value,
          value = dialCode && number
            ? `${ this.$refs.dialCode.value } ${ this.$refs.number.value.replace(/^0/, '') }`
            : ''

        this.$emit('update:modelValue', value)
      },

      toFlag(countryCode){
        const codePoints = countryCode
          .toUpperCase()
          .split('')
          .map(char => 127397 + char.charCodeAt())
        return String.fromCodePoint(...codePoints)
      }
    }
  }

</script>


<style scoped>

  .mfw-form-phone{
    @apply
      w-full
    ;
  }

  .fields{
    @apply
      w-full
      flex
      flex-nowrap
    ;
  }

  .phone-dialcode {
    @apply
      border-r-0
      mr-0
      rounded-r-none
    ;
    width: 4.3rem;
    overflow: hidden;
  }

  .phone-dialcode option .code{
    display: none;
  }

  .phone-number {
    @apply
      sm:text-sm
      rounded-l-none
      border-l-0
      w-full
      flex-grow
    ;
  }

  .hidden-field{
    display: none;
  }

</style>
