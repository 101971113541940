<template>
  <SidebarMenu title="Reports" />

  <PageContent>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul
          role="list"
          class="divide-y divide-gray-200"
        >
          <li
            v-for="application in applications"
            :key="application.applicant.email"
          >
            <a
              :href="application.href"
              class="block hover:bg-gray-50"
            >
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="min-w-0 flex-1 flex items-center">
                  <div class="flex-shrink-0">
                    <img
                      class="h-12 w-12 rounded-full"
                      src="@/assets/sample-user.jpg"
                      alt=""
                    >
                  </div>
                  <div
                    class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                  >
                    <div>
                      <p class="text-sm font-medium text-indigo-600 truncate">
                        {{ application.applicant.name }}
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500">
                        <DocumentReportIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span class="truncate">{{ application.applicant.email }}</span>
                      </p>
                    </div>
                    <div class="hidden md:block">
                      <div>
                        <p class="text-sm text-gray-900">
                          Last updated on
                          {{ ' ' }}
                          <time :datetime="application.date">{{ application.dateFull }}</time>
                        </p>
                        <p class="mt-2 flex items-center text-sm text-gray-500">
                          <TagIcon
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                          {{ application.stage }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </PageContent>
</template>

<script>
  import { ChevronRightIcon } from '@heroicons/vue/solid'
  import { DocumentReportIcon, TagIcon } from '@heroicons/vue/outline'
  import SidebarMenu from '@/components/layout/SidebarMenu'
  import PageContent from '@/components/layout/PageContent'

  const applications = [
    {
      applicant: {
        name: 'Herbicide Report Oct 2021',
        email: 'Herbicide report for all farms within area A',
      },
      date: '2021-11-01',
      dateFull: 'November 1st 2021',
      stage: 'herbicides, spraying',
      href: '#',
    },
    {
      applicant: {
        name: 'Pesticide Report Oct 2021',
        email: 'Pesticide report for all farms within area A, B and C',
      },
      date: '2021-11-01',
      dateFull: 'November 1st 2021',
      stage: 'pesticides, spraying',
      href: '#',
    },
  ]

  export default {
    components: {
      SidebarMenu,
      PageContent,
      TagIcon,
      ChevronRightIcon,
      DocumentReportIcon,
    },
    setup() {
      return { applications }
    },
  }
</script>
