<template>
  <select
    class="mfw-form-field mfw-dropdown-select"
    @input="$emit('update:modelValue', $event.target.value)"
  >
    <option />
    <option
      v-for="option in options"
      :key="option.id"
    >
      {{ option.title }}
    </option>
  </select>

  <MfwFormError :error="error" />

</template>


<script>

  import MfwFormFieldMixin from './mixins/MfwFormField.mixin'
  import MfwFormError from './MfwFormError'

  export default {
    name: 'MfwFormDropdown',
    components: { MfwFormError },
    mixins: [ MfwFormFieldMixin ],
    props: ['modelValue', 'label', 'options'],
    emits: ['update:modelValue']
  }

</script>
