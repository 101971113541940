<template>
  <div class="mfw-form-fields">
    <slot />
  </div>
</template>


<script>
  export default { name: 'MfwFormFields' }
</script>


<style>

  .mfw-form-fields {
    @apply
      grid
      grid-cols-1
      gap-y-3
      sm:grid-cols-6
      sm:gap-x-3
      border-transparent
    ;
  }

</style>
