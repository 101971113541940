<template>
  <form class="mfw-form bg-white">
    <slot />
  </form>

  <!--
    Debug view that shows the JSON form if the show-json attribute is used
  -->
  <div
    v-if="showJson"
    class="py-4 text-sm"
  >
    <span>Form as JSON:</span>
    <div class="mfw-form-json-area">
      <pre>{{ showJson }}</pre>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'MfwForm',
    props: {
      showJson: { default: false },
      showShadow: {
        default: true,
        type: Boolean
      }
    }
  }
</script>

<style>
.mfw-form {
  @apply
  mt-6
  space-y-0
  ;
}

.mfw-form-json-area {
  @apply
  border-4
  border-dashed
  bg-gray-50
  border-gray-300
  rounded-lg
  p-3
  ;
}
</style>
