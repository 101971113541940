<template>
  <SidebarMenu title="Settings" />

  <PageContent>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      User settings........
    </div>
  </PageContent>
</template>

<script>
  import SidebarMenu from '@/components/layout/SidebarMenu'
  import PageContent from '@/components/layout/PageContent'

  export default {
    components: {
      SidebarMenu,
      PageContent,
    },
    setup() {
      return {}
    },
  }
</script>
