<template>
  <div>
    <TransitionRoot
      as="template"
      :show="sidebarOpen"
    >
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img
                  src="@/assets/mfw-logo.svg"
                  alt="MyFarmWeb"
                >
              </div>
              <nav class="mt-5 px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  v-slot="{ isActive, navigate }"
                  :key="item.name"
                  :to="item.href"
                >
                  <a
                    :key="item.name"
                    :href="item.href"
                    :class="[
                      isActive
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                    ]"
                    @click="navigate"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        isActive
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-4 flex-shrink-0 h-6 w-6',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </a>
                </router-link>
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <router-link
                to="/settings"
                class="flex-shrink-0 group block"
              >
                <div class="flex items-center">
                  <div>
                    <img
                      class="inline-block h-10 w-10 rounded-full"
                      src="@/assets/sample-user.jpg"
                      alt=""
                    >
                  </div>
                  <div class="ml-3">
                    <p class="text-base font-medium text-white">
                      John Doe
                    </p>
                    <p
                      class="
                        text-sm
                        font-medium
                        text-gray-400
                        group-hover:text-gray-300
                      "
                    >
                      View profile
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center px-4 w-auto">
            <img
              src="@/assets/mfw-logo.svg"
              alt="MyFarmWeb"
            >
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1">
            <router-link
              v-for="item in navigation"
              v-slot="{ isActive, navigate }"
              :key="item.name"
              :to="item.href"
            >
              <a
                :key="item.name"
                :class="[
                  isActive
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
                @click="navigate"
              >
                <component
                  :is="item.icon"
                  :class="[
                    isActive
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <div class="md:pl-64 flex flex-col flex-1">
      <div
        class="
          sticky
          top-0
          z-10
          md:hidden
          pl-1
          pt-1
          sm:pl-3 sm:pt-3
          bg-gray-100
        "
      >
        <button
          type="button"
          class="
            -ml-0.5
            -mt-0.5
            h-12
            w-12
            inline-flex
            items-center
            justify-center
            rounded-md
            text-gray-500
            hover:text-gray-900
            focus:outline-none
            focus:ring-2
            focus:ring-inset
            focus:ring-indigo-500
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuIcon
            class="h-6 w-6"
            aria-hidden="true"
          />
        </button>
      </div>

      <div class="bg-white shadow">
        <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-4">
          <div
            class="
              py-4
              md:flex md:items-center md:justify-between
              lg:border-t lg:border-gray-200
            "
          >
            <div class="flex-1 min-w-0">
              <div class="flex items-center">
                <div>
                  <div class="flex items-center">
                    <h1
                      class="ml-3 text-2xl font-bold text-gray-900 sm:truncate"
                    >
                      {{ title }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <Menu
              as="div"
              class="ml-3 relative"
            >
              <div>
                <MenuButton
                  class="
                    max-w-xs
                    flex
                    items-center
                    text-sm
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    src="@/assets/sample-user.jpg"
                    alt=""
                  >
                </MenuButton>
              </div>
              <MenuItems
                class="
                  origin-top-right
                  absolute
                  right-0
                  mt-2
                  w-48
                  rounded-md
                  shadow-lg
                  bg-white
                  ring-1 ring-black ring-opacity-5
                  py-1
                  focus:outline-none
                "
              >
                <router-link
                  v-for="item in userNavigation"
                  :key="item.name"
                  :to="item.href"
                >
                  <MenuItem
                    :key="item.name"
                    v-slot="{ isActive }"
                  >
                    <a
                      :href="item.href"
                      :class="[
                        isActive ? 'bg-gray-100' : '',
                        'block py-2 px-4 text-sm text-gray-700',
                      ]"
                    >{{ item.name }}</a>
                  </MenuItem>
                </router-link>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import {
    ChartBarIcon,
    HomeIcon,
    MenuIcon,
    NewspaperIcon,
    ViewGridIcon,
    XIcon,
  } from '@heroicons/vue/outline'

  const navigation = [
    {
      name: 'Dashboard',
      href: '/',
      icon: HomeIcon,
      current: true,
    },
    {
      name: 'Instructions',
      href: '/instructions',
      icon: NewspaperIcon,
      current: false,
    },
    {
      name: 'Fields',
      href: '/fields',
      icon: ViewGridIcon,
      current: false,
    },
    {
      name: 'Reports',
      href: '/reports',
      icon: ChartBarIcon,
      current: false,
    },
  ]
  const userNavigation = [
    {
      name: 'Your Profile',
      href: '/profile',
    },
    {
      name: 'Settings',
      href: '/settings',
    },
    {
      name: 'Sign out',
      href: '#',
    },
  ]

  export default {
    components: {
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      MenuIcon,
      XIcon,
    },
    props: ['title'],
    setup() {
      const sidebarOpen = ref(false)

      return {
        navigation,
        userNavigation,
        sidebarOpen,
      }
    },
  }
</script>
