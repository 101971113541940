import { createRouter, createWebHistory } from 'vue-router'
import DashboardPage from '@/views/DashboardPage'
import SettingsPage from '@/views/SettingsPage'
import ProfilePage from '@/views/ProfilePage'
import ReportsPage from '@/views/ReportsPage'
import FieldsPage from '@/views/FieldsPage'
import InstructionsPage from '@/views/InstructionsPage'
import SignUpPage from '@/views/SignUpPage'
import TermsPage from '@/views/TermsPage'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpPage,
  },
  {
    path: '/instructions',
    name: 'Instructions',
    component: InstructionsPage,
  },
  {
    path: '/fields',
    name: 'Fields',
    component: FieldsPage,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: ReportsPage,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsPage,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
