<template>
  <input
    type="text"
    :name="id"
    autocomplete="off"
    class="mfw-form-field mfw-form-input"
    @input="onChanged"
  >
  <MfwFormError :error="error" />
</template>

<script>
  import MfwFormFieldMixin from './mixins/MfwFormField.mixin'
  import MfwFormError from './MfwFormError'

  export default {
    name: 'MfwFormInput',
    components: { MfwFormError },
    mixins: [MfwFormFieldMixin],
    props: ['id', 'modelValue', 'validator'],
    emits: ['update:modelValue'],
    methods: {
      onChanged(e) {
        this.$emit('update:modelValue', e.currentTarget.value)
      },
    },
  }
</script>
