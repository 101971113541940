<template>
  <div class="mt-1 relative rounded-md shadow-sm">
    <div class="absolute inset-y-0 left-0 flex items-center">
      <select
        id="country"
        v-model="country"
        class="mfw-form-phone-number-select"
        @change="onCountryChanged"
      >
        <option
          v-for="c in countries"
          :key="c.id"
          :value="c.id"
        >
          {{ c.id }}
        </option>
      </select>
    </div>
    <input
      id="phone-number"
      ref="phone-number"
      v-model="v"
      type="text"
      name="phone-number"
      class="mfw-form-field block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
      placeholder=""
      autocomplete="off"
      @input="onChanged"
    >
  </div>
  <MfwFormError :error="error" />
</template>


<script>
  import countries from '@/assets/countries.json'
  import MfwFormFieldMixin from '@/components/forms/mixins/MfwFormField.mixin'
  import MfwFormError from '@/components/forms/MfwFormError'

  const defaultCountry = 'za'

  export default {
    name: 'MfwFormPhoneNumber',
    components: { MfwFormError },
    mixins: [MfwFormFieldMixin],
    props: ['id', 'modelValue', 'validator'],
    emits: ['update:modelValue'],
    data() {
      return {
        country: defaultCountry,
        v: this.inferCountryCode(defaultCountry)
      }
    },
    computed: {
      countries() {
        return countries
      },
    },
    methods: {
      inferCountryCode(c) {
        let countrySelected = countries.find(country => country.id === c)
        return countrySelected.dialcode + ' '
      },
      onCountryChanged() {
        this.v = this.inferCountryCode(this.country)
        this.$refs['phone-number'].focus()
      },
      onChanged(e) {
        this.$emit('update:modelValue', e.currentTarget.value)
      },
    }
  }
</script>


<style scoped>

.mfw-form-phone-number-select {
  @apply
  focus:ring-indigo-500
  focus:border-indigo-500
  h-full py-0 pl-3 pr-7
  border-transparent
  bg-transparent
  text-gray-500
  sm:text-sm
  rounded-md
  ;
}

</style>
