<template>
  <sidebar-menu title="Form" />

  <page-content>
    <mfw-form :show-json="state">
      <mfw-form-fields>
        <!-- First name -->
        <mfw-form-group label="First name">
          <mfw-form-input v-model="v$.firstName" />
        </mfw-form-group>

        <!-- Surname -->
        <mfw-form-group label="Surname">
          <mfw-form-input v-model="v$.surname" />
        </mfw-form-group>

        <!-- E-mail address -->
        <mfw-form-group label="E-mail">
          <mfw-form-input v-model="v$.email" />
        </mfw-form-group>

        <mfw-form-group label="Comment">
          <mfw-form-input v-model="v$.comment" />
        </mfw-form-group>

        <!-- Country -->
        <mfw-form-group label="Country">
          <mfw-form-dropdown
            v-model="state.country"
            :options="countries"
          />
        </mfw-form-group>

        <!-- Choose gender -->
        <mfw-form-group label="Gender">
          <mfw-form-radio-group
            v-model="state.gender"
            :options="genders"
          />
        </mfw-form-group>

        <!-- Phone -->
        <mfw-form-group label="Phone">
          <mfw-form-phone
            v-model="v$.phone"
          />
        </mfw-form-group>

      </mfw-form-fields>

      <!-- Buttons -->
      <mfw-form-button-group>
        <mfw-form-button
          type="button"
          @click="onReset"
        >
          Reset
        </mfw-form-button>
        <mfw-form-button
          type="submit"
          @click="onSubmit"
        >
          Save
        </mfw-form-button>
      </mfw-form-button-group>
    </mfw-form>
  </page-content>
</template>

<script>

  import useValidate from '@vuelidate/core'
  import { required, email, minLength } from '@vuelidate/validators'
  import { computed, reactive } from 'vue'

  import PageContent from '@/components/layout/PageContent'
  import SidebarMenu from '@/components/layout/SidebarMenu'

  import MfwForm from '@/components/forms/MfwForm'
  import MfwFormButton from '@/components/forms/MfwFormButton'
  import MfwFormButtonGroup from '@/components/forms/MfwFormButtonGroup'
  import MfwFormDropdown from '@/components/forms/MfwFormDropdown'
  import MfwFormFields from '@/components/forms/MfwFormFields'
  import MfwFormGroup from '@/components/forms/MfwFormGroup'
  import MfwFormInput from '@/components/forms/MfwFormInput'
  import MfwFormPhone from '@/components/forms/MfwFormPhone'
  import MfwFormRadioGroup from '@/components/forms/MfwFormRadioGroup'

  import countries from '@/assets/countries.json'
  import genders from '@/assets/genders.json'


  export default {

    components: {

      PageContent,
      SidebarMenu,

      MfwForm,
      MfwFormButton,
      MfwFormButtonGroup,
      MfwFormDropdown,
      MfwFormFields,
      MfwFormGroup,
      MfwFormInput,
      MfwFormPhone,
      MfwFormRadioGroup
    },

    setup() {

      const state = reactive(
        {
          comment: '',
          country: '',
          email: '',
          firstName: '',
          gender: '',
          phone: '',
          surname: '',
        }
      )

      const rules = computed(
        () => {
          return {
            comment: '',
            country: '',
            email: {
              required,
              email
            },
            firstName: { required },
            gender: '',
            phone: { required },
            surname: {
              required,
              minLength: minLength(3)
            },
          }
        }
      )

      const v$ = useValidate(rules, state)

      return {
        state,
        v$,
        genders,
        countries
      }
    },

    methods: {

      onSubmit() {
        this.v$.$validate()
      },

      onReset() {
      },
    }
  }

</script>
