<script>

  export default {

    computed: {

      error() {
        return (
          this.validator &&
          this.validator.$errors &&
          this.validator.$errors.length
            ? this.validator.$errors[0].$message
            : false
        )
      }
    }
  }

</script>


<style>

.mfw-form-field {
  @apply
  block
  border-gray-300
  focus:ring-1
  focus:ring-primary-500
  focus:border-primary-700
  mt-1
  rounded-md
  sm:text-sm
  w-full
  ;
}

</style>
