<template>

  <fieldset class="mt-4">
    <div class="space-y-4">
      <div
        v-for="o in options"
        :key="o.id"
        class="flex items-center"
      >
        <input
          :id="o.id"
          name="."
          :value="o.title"
          type="radio"
          class="mfw-radiobutton-input"
          @input="$emit('update:modelValue', $event.target.value)"
        >

        <label
          :for="o.id"
          class="mfw-radiobutton-label"
        >
          {{ o.title }}
        </label>
      </div>


    </div>
  </fieldset>

</template>


<script>

  export default {
    name: 'MfwFormRadioGroup',
    props: ['modelValue', 'options'],
    emits: ['update:modelValue']
  }

</script>


<style>

  .mfw-radiobutton-label {
    @apply
      ml-3
      block
      text-sm
      font-medium
      text-gray-700
    ;
  }

  .mfw-radiobutton-input {
    @apply
      focus:ring-indigo-500
      h-4
      w-4
      text-indigo-600
      border-gray-300
    ;
  }

</style>